
<template>
    <OModal 
        name="newRamsDlg" 
        ref="newRamsDlg" 
        @shown="onShownDialog"
        @hidden="resetOnClose"
    > 
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-titel">{{ $t('New Risk') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="col-12">
                            <label class="form-label">{{ $t('Title') }} *</label>
                            <input class="form-control" :disabled="ramsCreated" v-model="newRamsObj.title">
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <label class="form-label">
                                    {{ $t('Type') }} *
                                </label>
                                <ODataLookup
                                    class="form-select"
                                    :data-object="local_dsTypesLkp"
                                    :bind="(sel: DataItemModel) => { newRamsObj.type_id = sel.ID; newRamsObj.type_name = sel.Name; }"
                                    v-model="newRamsObj.type_name"
                                    :disabled="ramsCreated"
                                >
                                    <OColumn width="75" field="ID" />
                                    <OColumn width="250" field="Name" :headerName="$t('Name')" />
                                </ODataLookup>
                            </div>
                            <div class="col">
                                <label class="form-label">
                                    {{ $t('Org. unit') }} * 
                                    <span v-if="orgunitOutOfContext" :title="$t('You are about to add a RAMS element to an orgunit outside of the current context you are in.')"><i class="bi bi-exclamation-triangle-fill" style="color:red;"></i></span>
                                </label>
                                <OrgUnitsLookup 
                                    :bind="(sel: DataItemModel) => { 
                                        newRamsObj.orgunit_id = sel.ID; 
                                        newRamsObj.orgunit_name = sel.OrgUnit; 
                                        newRamsObj.orgunit_idPath = sel.IdPath; 
                                    }" 
                                >
                                    <template #orgunit>
                                        <input type="text" :disabled="ramsCreated" class="form-select form-control" v-model="newRamsObj.orgunit_name">
                                    </template>
                                </OrgUnitsLookup>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <label class="form-label">{{ $t('Status') }} *</label>
                                <ODataLookup
                                    class="form-select form-control" 
                                    :data-object="local_dsRiskStatusLkp" 
                                    :bind="(sel: DataItemModel) => { newRamsObj.status_id = sel.ID, newRamsObj.status_name = sel.Name }" 
                                    disableRecent
                                    disablePinned
                                    :noClear="true"
                                    :value="newRamsObj.status_name"
                                    :disabled="ramsCreated"
                                >
                                    <OColumn field="ID" :headerName="$t('ID')" width="75" />
                                    <OColumn field="Name" :headerName="$t('Name')" width="175"/>
                                </ODataLookup>
                            </div>
                            <div class="col">
                                <label class="form-label">{{ $t('Source') }}</label>
                                <ODataLookup
                                    class="form-select form-control" 
                                    :disabled="preselectedSourceDefined || ramsCreated"
                                    :data-object="local_dsSourcesLkp" 
                                    :bind="(sel: DataItemModel) => { newRamsObj.source_id = sel.ID, newRamsObj.source_name = sel.Name }" 
                                    :value="newRamsObj.source_name"
                                    disableRecent
                                    disablePinned
                                    :whereClause="sourceWhereClause"
                                >
                                    <template #toolbarActions>
                                        <div class="form-check">
                                            <input class="form-check-input" id="dsSourcesLkp_RestrictToContext" type="checkbox" v-model="sourceLimitContext" @change="toggleSourceContext(sourceLimitContext)">
                                            <label class="form-check-label" for="dsSourcesLkp_RestrictToContext">{{ $t("Restrict to Context") }}</label>
                                        </div>
                                    </template>
                                    <OColumn field="ID" :headerName="$t('ID')" width="75" />
                                    <OColumn field="Name" :headerName="$t('Name')" width="175"/>
                                </ODataLookup>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-secondary" data-bs-dismiss="modal">{{ $t(!ramsCreated ? 'Cancel' : 'Close') }}</button>
                    <button v-if="!ramsCreated" class="btn btn-primary" @click="createNewRams">{{ $t('Add risk') }}</button>
                    <a v-else class="btn btn-success" :href="`/ramslogitem?ID=${ramsCreatedID}`" target="_blank"><i class="bi bi-box-arrow-up-right"></i> {{ $t('Open created risk') }} </a>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup lang="ts">
    import { getOrCreateProcedure, type IProcedureOptions } from 'o365-modules';
    import { getOrCreateDataObject, deleteDataObject, type DataItemModel, type DataObjectModels } from 'o365-dataobject';
    import { type NewRamsObj                    } from 'rams.libraries.vue.components.interfaces.NewRAMSInterfaces.ts'
    import { OOrgUnitsLookup as OrgUnitsLookup  } from 'o365-system-lookups';
    import { onUnmounted, ref, reactive, watch  } from 'vue'
    import { context as myContext               } from 'o365-modules';
    import { $t     } from 'o365-utils';
    import { alert  } from 'o365-vue-services';
    import { Modal  } from 'bootstrap'
    
    const props = defineProps({
        "PreselectedSource": {
            default: { ID: null, Name: null },
            type: Object
        }
    });
    const emits = defineEmits(["newRamsID"])

    const procAddRams = new getOrCreateProcedure<IProcedureOptions>({ id:"procAddRams", procedureName: "astp_RAMS_RiskAdd" });

    const initialNewRamsObj: NewRamsObj = {
        title: "", 
        type_name: "",
        type_id: null,
        orgunit_name: "",
        orgunit_id: null,
        orgunit_idPath: "",
        status_name: "", 
        status_id: null,
        source_name: "",
        source_id: null
    }
    const newRamsObj = reactive<NewRamsObj>({...initialNewRamsObj})  
    const newRamsDlg = ref<Modal | null>(null);
    const sourceWhereClause = ref<string>(`OrgUnitIdPath LIKE '${myContext.idPath}'`);
    const sourceLimitContext = ref<boolean>(true);
    const preselectedSourceDefined = ref<boolean>(props.PreselectedSource.ID !== null ? true : false);
    const ramsCreated = ref<boolean>(false);
    const ramsCreatedID = ref<number | null>(null);
    const orgunitOutOfContext = ref<boolean>(false);

    const local_dsTypesLkp = getOrCreateDataObject<DataObjectModels>({
        id: 'dsTypesLkp',
        viewName: 'atbv_RAMS_Types',
        maxRecords: 25,
        whereClause: "ShowInElements = 1",
        distinctRows: true,
        fields:
            [
                {name: "ID", type: "number"}, 
                {name: "Name", type: "string" }
            ]
    });

    const local_dsRiskStatusLkp = getOrCreateDataObject<DataObjectModels>({
        id: 'dsRiskStatusLkp',
        viewName: 'atbv_RAMS_Statuses',
        maxRecords: 25,
        distinctRows: true,
        fields:
            [
                {name: "ID", type: "number" }, 
                {name: "Name", type: "string" },
                {name: "DefaultChoice", type: "boolean"},
            ]
    });

    const local_dsSourcesLkp = getOrCreateDataObject<DataObjectModels>({
        id: 'dsSourcesLkp',
        viewName: 'atbv_RAMS_Sources',
        maxRecords: 25,
        distinctRows: true,
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Name", type: "string" }
            ]
    });

    watch(newRamsObj, (newVal: NewRamsObj) => {
        if(newVal.orgunit_idPath) {
            checkOrgOutsideOfContext(newVal.orgunit_idPath, myContext.idPath);
        }
    });

    const checkOrgOutsideOfContext = (newRamsObjIdPath: string, contextIdPath: string) => {
        if(!newRamsObjIdPath.startsWith(contextIdPath)) {
            orgunitOutOfContext.value = true;
        } else {
            orgunitOutOfContext.value = false;
        }
    }

    const setDefaultStatus = async () => {
        await local_dsRiskStatusLkp.load().then(() => {
            const defaultStatus: (DataItemModel | null) = local_dsRiskStatusLkp.data.find((item: DataItemModel) => item.DefaultChoice) !== undefined ? local_dsRiskStatusLkp.data.find((item: DataItemModel) => item.DefaultChoice) : null;
            if(defaultStatus !== null) {
                newRamsObj.status_id = defaultStatus.ID;
                newRamsObj.status_name = defaultStatus.Name;
            }
        });
    }

    const toggleSourceContext = async (pLimitContext: boolean) => {
        if(pLimitContext) {
            sourceWhereClause.value = `OrgUnitIdPath LIKE '${myContext.idPath}'`;
        } else {
            sourceWhereClause.value = "";
        }

        local_dsSourcesLkp.recordSource.whereClause = sourceWhereClause.value;
        await local_dsSourcesLkp.load();
    }

    const createNewRams = async () => {
        // Validation on status and org might be removed since its "noClear" - an extra check doesnt hurt
        if(!newRamsObj.title) { displayAlert($t("Title is required.")); return; }
        if(!newRamsObj.type_id) { displayAlert($t("Type is required.")); return; }
        // if(!newRamsObj.orgunit_id) { displayAlert($t("Org. unit is required.")); return; }
        // if(!newRamsObj.status_id) { displayAlert($t("Status is required.")); return; }

        await procAddRams.execute({
            Title: newRamsObj.title,
            OrgUnit_ID: newRamsObj.orgunit_id,
            Status_ID: newRamsObj.status_id,
            Type_ID: newRamsObj.type_id,
            Source_ID: newRamsObj.source_id
        }).then((response: any) => {
            if(response.Table[0]["ID"]) {
                alert($t('Risk was successfully created.'), 'success', { autohide: true, delay: 3000});
                emits("newRamsID", response.Table[0]["ID"]);
                ramsCreated.value = true;
                ramsCreatedID.value = response.Table[0]["ID"];
            } else {
                alert($t('Something went wrong, try again.'), 'danger', { autohide: true, delay: 3000});
            }
        })
    }

    const displayAlert = (msg: string) => {
        alert(msg, 'warning', {autohide: true, delay: 3000});
    }

    const onShownDialog = () => {
        newRamsObj.orgunit_id = myContext.id; 
        newRamsObj.orgunit_name = myContext.name; 
        newRamsObj.orgunit_idPath = myContext.idPath;

        if(props.PreselectedSource.ID !== null) {
            newRamsObj.source_id = props.PreselectedSource.ID;
            newRamsObj.source_name = props.PreselectedSource.Name;
        }

        setDefaultStatus();
    }

    const resetOnClose = () => {
        Object.assign(newRamsObj, initialNewRamsObj);
        ramsCreated.value = false;
        ramsCreatedID.value = null;
    }

    onUnmounted(() => {
        deleteDataObject(local_dsTypesLkp.id, local_dsTypesLkp.appId)
        deleteDataObject(local_dsRiskStatusLkp.id, local_dsRiskStatusLkp.appId)
        deleteDataObject(local_dsSourcesLkp.id, local_dsSourcesLkp.appId)
    })
</script>